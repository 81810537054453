.flex-container {
    display: flex;
}

.horizontal {
    flex-direction: row;
}

.horizontal-reverse {
    flex-direction: row-reverse;
}

.vertical {
    flex-direction: column;
}

.vertical-reverse {
    flex-direction: column-reverse;
}

.content-start {
    justify-content: flex-start;
}

.content-end {
    justify-content: flex-end;
}

.content-center {
    justify-content: center;
}

.content-between {
    justify-content: space-between;
}
.content-around {
    justify-content: space-around;
}

.items-center {
    align-items: center;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: end;
}