.btn-addtrailer {
    position: relative;
    top: 18px;
    right: 35px;
    font-weight: 500;
}

.btn-addtrailer:hover {
    text-decoration: none;
}

.search-trailer {
	position: absolute;
    left: 22px;
    top: 28px;
    z-index: 1;
    font-size: 16px !important;
}

.nav-tabs {
    border-bottom: none;
    box-shadow: inset 0 -2px 0 0 #eeeeee;
}

.nav-tabs>li>a {
    -webkit-transition: box-shadow;
    -o-transition: box-shadow;
    transition: box-shadow;
    -webkit-transition-duration: 350ms;
    transition-duration: 350ms;
}

.nav-tabs>li.active>a {
    box-shadow: inset 0 -2px 0 0 #3B78E7;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
    border: none;
    color: #000;
}

.nav-tabs>li:not(.active)>a {
    color: #555;
}

.nav-tabs>li:not(.active)>a:hover {
    background: transparent;
    border-color: #fff;
    color: #555;
}

.tab-content {
    height: 100%;
}

.tab-content>.active {
    height: 100%;
}

.panel-body.lc-body-with-header .active {
    height: 100%;
}

.panel-default>.panel-heading+.panel-collapse .panel-body {
    background-color: #fff;
}

.panel-default>.panel-heading+.panel-collapse .panel-body:hover {
    background-color: #fff;
}

.accordion-toggle {
    width: 100%;
    display: inline-block;
    padding: 10px;
}

#admin-trailer-view  .panel-heading {
    padding: 0;
}

.b-transparent {
    background: transparent;
}

.b-transparent:hover {
    background: transparent;
}
