#new_estimate_page {
    margin-top: 100px;

    .estimate-panel {
        height: auto;
        width: 100%;
        min-width: 460px;

        .panel-body {
            overflow: auto;
        }
    }

    .control-label:after {
        content: "*";
        color: red;
        margin-left: 2px;
    }

    .summary-panel {
        min-width: 440px;
        height: auto;
        margin-left: 30px;

        .item-row {
            margin: 0px 0px 7px 0px;
            border-bottom: 1px solid #c7c7c7;
            position: relative;
            min-height: 45px;
            padding: 7px 15px;

            label {
                min-width: 50%;
            }
        }

        .item-row > span {
            text-align: end;
        }
    }

    #save-btn {
        width: 40%;
    }

        
    .has-error {
        border-color: rgba(255, 0, 19, 0.77) !important;
        box-shadow: 0 0 8px rgba(233, 102, 102, 0.77) !important; 
    }

    .control-label:after {
        content: "*";
        color: red;
        margin-left: 2px;
    }
}