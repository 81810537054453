#estimate_config_page {
    margin-top: 70px;

    .italic {
        font-style:italic;
    }
    
    .underline {
        text-decoration:underline;
    }

    .edit-name-input {
        width: 400px;
    }
    .category-dropdown {
        margin:-10px 0px 0px 15px; 
        max-height: 400px; min-width: 200px; 
        overflow: auto;

        li {
            border-top: 1px solid #c7c7c7; 
        }

        li:first-child {
            border-top: 0
        }

        .category-btn {
            border: none;
            border-radius: 0;
            text-align: initial;
            padding: 3px 3px;
        }
    }

    .category-dropdown-overflow {
         max-height: none;
    }

    .custom-accordion {
        overflow: auto;
        max-height: 400px; 

        h4 {
            font-size: 14px;
        }
            
        .panel {
            border-radius: 0;
            box-shadow: none;
        }

        .panel-group {
            margin:5px 0px 0px 10px; 
            min-width: 300px; 
        }

        .panel-heading {
            background-color: #fff !important;
            border: none;
            border-radius: 0;
            text-align: initial;
            padding: 3px 3px;
        }

        .panel-body {
            padding: 5px;

            ul {
                margin: 0;
                padding: 0;
            }
        }

        .accordion-toggle {
            padding: 0;
        }
    }

    .tab-content>.flex-container.active {
        display: flex !important;
    }

    .config-panel {
        height: auto;
        width: 100%;
        min-width: 460px;
        .panel-body {
            overflow: auto;
            max-height: 80%;
        }
        .category-wrap {
            padding: 5px 10px;
            ul {
                margin: 0;
                padding: 0;
            }
            background-color: rgb(249, 249, 249);

            .category-item-list {
                line-height: 1.42;
                font-size: 16px;
                min-width: 90%;

                .category-item {
                    padding: 6px 12px;
                    color: #555;
                    background-color: #fff;
                    border-radius: 4px;
                    min-height: 24px;
                    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                    span {
                        margin-left: 10px;
                        width: 95%;
                    }
                }

                .optional {
                    height: 30px;
                    font-size: 1.2em;
                }

            }

            label {
                margin: 3px auto;
                line-height: 1.2em;
            }

            .reset-all-link {
                padding-top: 10px;
                padding-bottom: 5px;
                margin-left: 45px;
                color: gray;
                text-decoration: underline;
            }
        }
    }

    .summary-panel {
        min-width: 385px;
        height: auto;
        margin-left: 30px;

        .item-row {
            margin: 0px 0px 7px 0px;
            border-bottom: 1px solid #c7c7c7;
            position: relative;
            min-height: 45px;
            padding: 7px 15px;
        }
        div:nth-child(2) {
            padding-top: 15px;
        }
    }

    .config-panel-button-row {
        background-color:#282828;
        padding:8px;
        margin-bottom:17px;
        box-shadow:0px 2px 5px 0px #999999;
    }

    .btn-position {
        font-size:.6em;
        padding-bottom:3px;
    }

    .validation-err {
        color: red;
    }
    
    .has-error {
        border-color: rgba(255, 0, 19, 0.77) !important;
        box-shadow: 0 0 8px rgba(233, 102, 102, 0.5) !important; 
    }
        
    .has-error {
        border-color: rgba(255, 0, 19, 0.77) !important;
        box-shadow: 0 0 8px rgba(233, 102, 102, 0.77) !important; 
    }

    .control-label:after {
        content: "*";
        color: red;
        margin-left: 2px;
    }
}

#estimate_error {
    height: 90%;
    width: 100%;

    .err_status {
        font-size: 120px;
        text-align: center;
    }

    .err_description {
        font-size: 32px;
        text-align: center;
    }
}