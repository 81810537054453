#usersGrid .k-grid-content {
    height: calc(100% - 69px);
}

.lc-input {
    border-color: rgb(230, 230, 230);
}

input {
    /*outline:none !important;*/
}

.lc-input:hover {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.search-dealership-container {
    background-color: #fff;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 45px;
}

.search-dealership-container .search-icon-container {
    left: 20px !important;
    top: 22px !important;
}

.btn-add-user-container {
    display: inline-block;
    float: right;
    margin-right: 15px;
    font-weight: 500;
}

.btn-add-user {
    background-color: transparent;
    color: #4caf50 !important;
    font-size: 1.1em;
}

.k-grid-header th.k-header {
   border-width: 0 0 1px 0px !important;
}