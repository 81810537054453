#items_page {
    .w-80 {
        width: 80%;
    }
    
    table {

        tr {

            #draggable {
                
            }

            #component_name {
                width: 20%;
            }

            #super_category {
                width: 20%;
            }

            #sub_category {
                width: 15%;
            }

            #selection_type {
                width: 15%;
            }

            #group {
                width: 14%;
            }

            #actions {
                width: 14%;
            }

        }
    }
}