#item-disclaimer {

    .clean-modal-header { 
        position: relative;
        line-height: 52px;
        min-height: 56px;
        vertical-align: middle;
        border-radius: 2px 2px 0 0;
    }

    header {
        display: inline-block;
        padding: 14px 24px 5px 24px;
        vertical-align: middle;
        line-height: 28px;
        font-size: 26px;
        font-weight: 100;
    }

    .clean-modal-body {
        padding: 12px 24px 0 24px;
        position: relative;
    }

    textarea {
        width: 100%;
        margin: 0 0 12px;
        padding: 2px 10px;
        height: 150px;
        font-size: 18px;
    } 

    p {
        width: 100%;
        margin: 0 0 12px;
        padding: 2px 10px;
        font-size: 18px;
    }

    .clean-modal-footer {
        padding: 0 24px 12px 24px;
        display: flex;
        flex-direction: row-reverse;
        button {
            margin-left: 8px;
            min-width: 60px;
        }
    }

    .clean-modal-footer-one-button {
        padding: 0 24px 12px 24px;
        display: flex;
        justify-content: center;
        button {
            margin-left: 8px;
            min-width: 100px;
        }
    }
}