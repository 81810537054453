.select-page {
    width: 50px;
    text-align: center;
}
.pagination li a input {
    padding: 0;
    margin: -5px 0;
}

thead th {
    cursor: pointer;
}