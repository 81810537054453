#estimates {
    .right-menu {
        position: relative;
        top: -10px;
        float: right;
    }

    .retail-price {
        width:  12.4em;
    }
}

#estimatesFilterModal {
    .k-datepicker {
        width: 49%;

        .k-picker-wrap {
            border-color: #e6e6e6;

            input {
                height: 25px !important;
            }
        }
    }
}

#estimate-attachments-modal {
    .custom-file-input {
        padding: 5px;
        color: #555;
        border: 1px solid #e6e6e6;
        border-radius: 3px;
        vertical-align: middle;
    }

    .custom-file-input:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    .custom-file-input:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    }

    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }

    .custom-file-input::before {
        content: 'Browse';
        color: #666;
        background: #fff;
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 20px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-align: center;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10pt;
    }

    .custom-file-input:active {
        outline: 0;
    }

    .custom-file-input:active::before {
        background: -webkit-linear-gradient(#e3e3e3, #f9f9f9);
        background: -moz-linear-gradient(#e3e3e3, #f9f9f9);
        background: -o-linear-gradient(#e3e3e3, #f9f9f9);
        background: linear-gradient(#e3e3e3, #f9f9f9); 
    }
}