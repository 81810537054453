#pricing_page {
    margin-top: 50px;

    .panel {
      padding: 10px 15px 15px 15px;
      margin-bottom: 0;
    }

    @media screen and (max-width: 1366px) {
      .k-grid-content {
        max-height: 62vh;
      }
    }

    @media screen and (min-width: 1367px) and (max-width: 1550px) {
      .k-grid-content {
        max-height: 64vh;
      }
    }

    @media screen and (min-width: 1551px) {
      .k-grid-content {
        max-height: 68vh;
      }
    }


    .btn-position {
        font-size:.6em;
        padding-bottom:3px;
    }

    .contact-info-form {
      list-style-type: none;
      margin: 30px 0;
      padding: 0;
    }

    .contact-info-form li {
      margin: 10px 0;
    }

    .contact-info-form label {
      display: inline-block;
      width: 100px;
      text-align: right;
      font-weight: bold;
    }
}