html {
    height: 100%;
}

body {
    height: 100%;
}

.nav,
.pagination,
.carousel,
.panel-title a,
.clickable,
.list-group a {
    cursor: pointer;
}

.panel,
.add-shadow {
    box-shadow: 2px 2px 10px gray;
}

.btn-default {
    background-color: #fff;
}

.panel-heading {
    background-color: #B8B8B8 !important;
    font-weight: bolder;
}

.error {
    background-color: red;
}

.action-button {
    float: right;
    margin-top: -3px;
    margin-left: 10px;
}

.panel-heading {
    font-size: 16px;
}

.list-group:not(.full-length) {
    max-height: 600px;
    overflow: auto;
}

.input-group {
    margin: 0 auto;
}

.modal-title {
    text-align: center;
}

#formDiv {
    height: 280px;
}

textarea {
    resize: none;
}

#floating-form {
    opacity: .6;
    position: absolute;
    background-color: black;
    border-radius: 5px;
    width: 100px;
    height: 100px;
}

#inner-form {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: 10px 0 0 10px;
}

#totalid {
    text-align: center;
    font-size: 12px;
    padding: 8px 6px;
}

.config-print td {
    text-align: left;
    height: 10px !important;
    padding: 1px !important;
}

@media screen {
    .print-only {
        display: none;
    }
}

@media print {
    header nav,
    footer,
    .navbar,
    #estimateConfigControls,
    .no-print {
        display: none;
    }
    @page {
        margin: 0.75cm;
    }
    .config-print {
        font-size: 14px;
    }
    .config-print td {
        text-align: left;
        padding: 0px;
    }
}

th,
td,
tr {
    vertical-align: middle !important;
}

.inner-table {
    margin: 0;
    /*border-collapse: collapse;*/
    border-style: hidden;
}

.inner-table td,
.inner-table th {
    /*border: 1px solid gray;*/
}


/* pc  */

@media screen {
    #wrapper {
        min-height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        display: inline-block;
    }
    #main-wrapper {
        overflow: none;
    }
    #sidebar-wrapper {
        height: 100%;
        position: fixed;
        border-right: 1px solid gray;
    }
    #lg-sidebar-wrapper {
        height: calc(100% - 100px) !important;
        position: fixed;
        border-right: 1px solid gray;
    }
    .lg-trailers-list {
        height: calc(100% - 85px) !important;
    }
    .lg-height-full {
        height: 100%;
    }
    .lg-overflow-auto {
        overflow: auto;
    }
    .lg-content-container {
        margin-top: 67px;
        height: 100%;
    }
    .lg-body-with-header {
        height: -moz-calc(100% - 45px);
        height: -webkit-calc(100% - 45px);
        height: calc(100% - 45px);
    }
    .lc-body-with-header {
        height: -moz-calc(100% - 45px);
        height: -webkit-calc(100% - 45px);
        height: calc(100% - 45px);
    }
    .lc-align-center {
        text-align: center;
    }
    .lc-body-with-25 {
        height: -moz-calc(100% - 25px);
        height: -webkit-calc(100% - 25px);
        height: calc(100% - 25px);
    }
    .lc-body-with-65 {
        height: -moz-calc(100% - 65px);
        height: -webkit-calc(100% - 65px);
        height: calc(100% - 65px);
    }
    .lg-body-with-75 {
        height: -moz-calc(100% - 75px);
        height: -webkit-calc(100% - 75px);
        height: calc(100% - 75px);
    }
    .lc-body-with-105 {
        height: -moz-calc(100% - 105px);
        height: -webkit-calc(100% - 105px);
        height: calc(100% - 105px);
    }
    .lc-body-with-140 {
        height: -moz-calc(100% - 140px);
        height: -webkit-calc(100% - 140px);
        height: calc(100% - 140px);
    }
    .lc-body-with-180 {
        height: -moz-calc(100% - 180px);
        height: -webkit-calc(100% - 180px);
        height: calc(100% - 180px);
    }
    .lc-table-container {
        height: -moz-calc(100% - 105px) !important;
        height: -webkit-calc(100% - 105px) !important;
        height: calc(100% - 105px)!important;
        overflow: auto!important;
    }
    @media (max-width: 992) {
        height: -moz-calc(100% - 120px) !important;
        height: -webkit-calc(100% - 120px) !important;
        height: calc(100% - 120px)!important;
    }
    .p-t-0 {
        padding-top: 0px !important;
    }
    .m-b-0 {
        margin-bottom: 0px !important;
    }
    .p-b-20 {
        padding-bottom: 20px !important;
    }
    .p-0 {
        padding: 0!important;
    }
    .p-15 {
        padding: 15px!important;
    }
    .p-l-0 {
        padding-left: 0 !important;
    }
    .p-l-25 {
        padding-left: 25px !important;
    }
    .p-l-20 {
        padding-left: 20px !important;
    }
    .p-t-5 {
        padding-top: 5px !important;
    }
    .m-t-10 {
        margin-top: 10px !important;
    }
    .m-t-15 {
        margin-top: 15px !important;
    }
    .m-t-25 {
        margin-top: 25px !important;
    }
    .m-15 {
        margin: 15px !important;
    }
    .m-l-0 {
        margin-left: 0px !important;
    }
    .m-l-15 {
        margin-left: 15px !important;
    }
    .m-r-10 {
        margin-right: 10px;
    }
    .m-t-30 {
        margin-top: 30px !important;
    }
    .m-t-50 {
        margin-top: 50px !important;
    }
    .m-b-10 {
        margin-bottom: 10px !important;
    }
    .m-b-50 {
        margin-bottom: 50px !important;
    }
    .w-100 {
        width: 100% !important;
    }
    .w-45 {
        width: 45% !important;
    }
    .w-49 {
        width: 49% !important;
    }
    .w-50 {
        width: 50% !important;
    }
    .w-10 {
        width: 10% !important;
    }
    .w-15 {
        width: 15% !important;
    }
    .w-20 {
        width: 20% !important;
    }
    .w-40 {
        width: 40% !important;
    }
    .w-90 {
        width: 90% !important;
    }
    .w-200px {
        width: 200px !important;
    }
    .inline-block {
        display: inline-block;
    }
    @media (max-width: 992px) {
        .lg-right-panel {
            margin-top: 30px;
        }
        .lc-right-panel {
            margin-top: 30px;
        }
    }
    .lc-panel-new {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px 17px;
        display: inline-block;
        background: #fff;
        border: 1px solid #E6E9ED;
        -webkit-column-break-inside: avoid;
        -moz-column-break-inside: avoid;
        column-break-inside: avoid;
        opacity: 1;
        transition: all .2s ease;
    }
    .lc-panel-new-title {
        border-bottom: 2px solid #E6E9ED;
        padding: 1px 5px 6px;
        margin-bottom: 10px;
    }
    .lc-panel-new-content {
        padding: 0 5px 6px;
        position: relative;
        width: 100%;
        margin-top: 5px;
    }
    .lc-height-full {
        height: 100%;
    }
    .lc-height-with-15 {
        height: -moz-calc(100% - 15px);
        height: -webkit-calc(100% - 15px);
        height: calc(100% - 15px);
    }
    .lc-content-container {
        position: relative;
        top: 67px;
        height: -moz-calc(100% - 70px);
        height: -webkit-calc(100% - 70px);
        height: calc(100% - 70px);
    }
    .lc-content-container-trailerconfig {
        height: calc(100% - 75px);
        width: 99%;
    }
    .lc-overflow-auto {
        overflow: auto;
    }
    .m-t-80 {
        margin-top: 80px;
    }
    .m-t-45 {
        margin-top: 45px !important;
    }
    .p-r-0 {
        padding-right: 0px !important;
    }
    .p-l-30 {
        padding-left: 30px !important;
    }
    .lc-color-green {
        color: #4caf50;
    }
    .lc-with-80 {
        height: -moz-calc(100% - 80px);
        height: -webkit-calc(100% - 80px);
        height: calc(100% - 80px);
    }
    .lc-body-with-45 {
        height: -moz-calc(100% - 45px) !important;
        height: -webkit-calc(100% - 45px) !important;
        height: calc(100% - 45px) !important;
    }
    .lc-body-with-50 {
        height: -moz-calc(100% - 50px) !important;
        height: -webkit-calc(100% - 50px) !important;
        height: calc(100% - 50px) !important;
    }
    .lc-body-with-60 {
        height: -moz-calc(100% - 60px) !important;
        height: -webkit-calc(100% - 60px) !important;
        height: calc(100% - 60px) !important;
    }
    .lc-c-white {
        color: #fff;
    }
    .admin-items-list-group {
        max-height: none !important;
        margin: 0px !important;
    }
    .admin-items-button-group {
        width: 240px;
    }
    .search-icon-container {
        position: absolute;
        left: 10px;
        top: 8px;
    }
    .btn-search-user {
        position: absolute;
        right: -35px;
        top: 0px;
        padding: 3px 10px 4px !important;
    }
    .search-arrow {
        font-size: 11px !important;
        color: #fff;
        font-weight: 100 !important;
    }
    /* KENDO OVERIDES */
    .k-grid td {
        border-style: solid;
        border-width: 0 0 1px 1px !important;
        border-right: none !important;
        border-left: none !important;
    }
    .k-state-selected {
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
    }

    .k-tooltip {
      background: #737373 !important;
      color: #fff !important;
      font-size: 12px !important;
      background-image: none;
    }

    .k-callout.k-callout-s {
      background-image: none;
      border-top-color: #737373;
    }

    /* KENDO OVERIDES */
    .lc-bg-gray {
        background-color: #9E9E9E;
    }
    .lc-c-black {
       // color: #cccccc !important;
    }

    .lc-overflow-y-auto {
        overflow-y: auto;
    }

    /* pc  */

    .btn-success {
        /*color: #4caf50 !important;*/
    }

    .btn-link {
        color: #2196f3;
        font-weight: normal;
        border-radius: 0;
    }
    .btn-link,
    .btn-link:active,
    .btn-link.active,
    .btn-link[disabled],
    fieldset[disabled] .btn-link {
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .btn-link,
    .btn-link:hover,
    .btn-link:focus,
    .btn-link:active {
        border-color: transparent;
    }
    .btn-link:hover,
    .btn-link:focus {
        color: #0a6ebd;
        text-decoration: none;
        background-color: transparent;
    }
    .btn-link[disabled]:hover,
    fieldset[disabled] .btn-link:hover,
    .btn-link[disabled]:focus,
    fieldset[disabled] .btn-link:focus {
        color: #777777;
        text-decoration: none;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    .panel-heading {
        //color: #cccccc !important;
    }
    .view-frame {
        height: 100%;
    }
    #sidebar {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }
    #sidebar .list-group-item {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }
}

@media (max-width: 992px) {
    body {
        padding-top: 0px;
    }
}

@media (min-width: 992px) {
    #main-wrapper {
        float: right;
    }
}

@media (max-width: 992px) {
    #main-wrapper {
        padding-top: 0px;
    }
    #main {
        margin-top: 20px;
    }
}

@media (max-width: 992px) {
    #sidebar-wrapper {
        position: static;
        height: auto;
        max-height: 300px;
        border-right: 0;
    }
}

.animate-repeat.ng-move,
.animate-repeat.ng-enter {
    transition: all ease 0.2s;
}

.animate-repeat.ng-leave {
    transition: all ease-out 0.2s;
}

.animate-repeat.ng-leave.ng-leave-active,
.animate-repeat.ng-move,
.animate-repeat.ng-enter {
    opacity: 0;
    /*max-height:0;*/
}

.animate-repeat.ng-leave,
.animate-repeat.ng-move.ng-move-active,
.animate-repeat.ng-enter.ng-enter-active {
    opacity: 1;
    /*max-height:30px;*/
}

.well {
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 1px 1px 1px #888888;
}

.logo {
    width: 240px;
    margin: auto;
}

.admin-item-row .form-group {
    margin-bottom: 0px;
}

.admin-item-row {
    right: 0;
}

.admin-item-row .form-group.row > div,
.admin-item-row .form-group.row > label {
    display: inline-block;
}

.ui-sortable-helper td:first-child {
    width: 4%;
}

.ui-sortable-helper td:nth-child(2) {
    width: 30%;
}

.ui-sortable-helper td:nth-child(3) {
    width: 22%
}

.ui-sortable-helper td:nth-child(4) {
    width: 21%;
}

.ui-sortable-helper td:last-child {
    width: 15%;
}


/*#categories tr .td-4{
  width: 4%;
}
#categories tr .td-35 {
  width: 35%;
}
#categories tr .td-20 {
  width: 20%;
}*/

.navbar-collapse > ul {
    width: 100%;
}

@media(min-width: 768px){
    .logout {
        margin-right: 10px;
    }
}

.lc-navbar {
    /*background-color: #5F5F5F;*/
    background-color: #282828;
}

.lc-navbar>li>a {
    /*color:white !important;*/
    color: #FFFFFF !important;
}

.lc-navbar>li>a:hover {
    color: #FFFFFF !important;
    border-bottom: 6px solid #FFFFFF !important;
    /*background-color:#B8B8B8 !important;*/
}

.lc-navbar-brand {
    /*color:white !important;*/
    color: #FFFFFF !important;
    text-decoration: none;
}

.lc-navbar-brand:hover {
    color: white !important;
}

.lc-navbar>.active>a {
    color: #FFFFFF !important;
    border-bottom: 6px solid #909090 !important;
    background-color: #484848 !important;
}

.lc-navbar>.active>a:hover {
    color: #FFFFFF !important;
    border-bottom: 6px solid #909090 !important;
    background-color: #484848 !important;
}

.view-container {
    padding: 18px !important;
    height: 100%;
}

.lc-btn {
    background-color: #222824;
    color: #FFFFFF !important;
    /*border-color: #505d54;*/
}

.lc-btn:hover {
    background-color: #39433c;
    /*border-color: #505d54;*/
    color: #FFFFFF;
}

.lc-btn-delete {
    background-color: #B80000;
    color: #FFFFFF !important;
    /*border-color: #673432;*/
}

.lc-c-red {
  color: #B80000 !important;
}

.lc-btn-delete:hover {
    background-color: #A53434;
    color: #FFFFFF;
    /*border-color: #942e2e;*/
}

.lc-btn-light {
    background-color: #888888;
    color: #FFFFFF !important;
    border-color: #819285;
}

.lc-btn-light:hover {
    background-color: #909090;
    color: #FFFFFF !important;
    border-color: #738678;
}

.lc-modal-title {
    background-color: #606060 !important;
    color: #FFFFFF !important;
    margin-bottom: 36px;
    box-shadow: 0 3px #ECE7DF;
}

a.list-group-item.active {
   /* background-color: #505050 !important;
    border-color: #000000 !important;*/
}

.lc-panel {
    background-color: #B8B8B8;
}

.lc-btn-blue {
    background-color: #006699;
    color: #FFFFFF;
    border-color: #006699;
}

.lc-btn-green {
    background-color: #336600;
    color: white;
    /*border-color: #336600;*/
}


/*bgm buttons*/

.bgm-white {
    background-color: #ffffff !important;
}

.c-white {
    color: #ffffff !important;
}

.bgm-black {
    background-color: #000000 !important;
}

.c-black {
    color: #000000 !important;
}

.bgm-brown {
    background-color: #795548 !important;
}

.c-brown {
    color: #795548 !important;
}

.bgm-pink {
    background-color: #e91e63 !important;
}

.c-pink {
    color: #e91e63 !important;
}

.bgm-red {
    background-color: #f44336 !important;
}

.c-red {
    color: #f44336 !important;
}

.bgm-blue {
    background-color: #2196f3 !important;
}

.c-blue {
    color: #2196f3 !important;
}

.bgm-purple {
    background-color: #9c27b0 !important;
}

.c-purple {
    color: #9c27b0 !important;
}

.bgm-deeppurple {
    background-color: #673ab7 !important;
}

.c-deeppurple {
    color: #673ab7 !important;
}

.bgm-lightblue {
    background-color: #03a9f4 !important;
}

.c-lightblue {
    color: #03a9f4 !important;
}

.bgm-cyan {
    background-color: #00bcd4 !important;
}

.c-cyan {
    color: #00bcd4 !important;
}

.bgm-teal {
    background-color: #009688 !important;
}

.c-teal {
    color: #009688 !important;
}

.bgm-green {
    background-color: #4caf50 !important;
}

.c-green {
    color: #4caf50 !important;
}

.bgm-lightgreen {
    background-color: #8bc34a !important;
}

.c-lightgreen {
    color: #8bc34a !important;
}

.bgm-lime {
    background-color: #cddc39 !important;
}

.c-lime {
    color: #cddc39 !important;
}

.bgm-yellow {
    background-color: #ffeb3b !important;
}

.c-yellow {
    color: #ffeb3b !important;
}

.bgm-amber {
    background-color: #ffc107 !important;
}

.c-amber {
    color: #ffc107 !important;
}

.bgm-orange {
    background-color: #ff9800 !important;
}

.c-orange {
    color: #ff9800 !important;
}

.bgm-deeporange {
    background-color: #ff5722 !important;
}

.c-deeporange {
    color: #ff5722 !important;
}

.bgm-gray {
    background-color: #9e9e9e !important;
}

.c-gray {
    color: #9e9e9e !important;
}

.bgm-bluegray {
    background-color: #607d8b !important;
}

.c-bluegray {
    color: #607d8b !important;
}

.bgm-indigo {
    background-color: #3f51b5 !important;
}

.c-indigo {
    color: #3f51b5 !important;
}

.bgm-lightgray {
    background-color: #B8B8B8 !important;
}

.lc-table {
    background-color: white;
    box-shadow: 2px 2px 10px gray;
}

.lc-table>thead {
    background-color: #b8b8b8;
}

.lc-logo {
    width: 150px;
    margin-right: 2px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    border-radius: 0px 0px 7px 0px;
    box-shadow: 2px 2px 10px gray;
}


/*Changes Mateo*/

.lc-btn-margin {
    margin-bottom: 36px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    left: -moz-calc(50% - 60px);
    left: -webkit-calc(50% - 60px);
    left: calc(50% - 60px);
    top: -moz-calc(50% - 60px);
    top: -webkit-calc(50% - 60px);
    top: calc(50% - 60px);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.st-sort-ascent:before {
    content: '\25B2';
}

.st-sort-descent:before {
    content: '\25BC';
}

.st-selected {
    background-color: #d9edf7 !important;
}

.dealer-estimates-container {
    height: calc(100% - 90px);
}

.dealer-estimates-list-container {
    height: calc(100% - 15px);
}

.dealer-estimates-list {
    height: 100%;
}

#admin-trailers-container {
    margin-top: 70px;
    height: 100%;
}

@media (max-width: 580px) {
    #categories {
        font-size: 11px;
    }
}

.form-group.required .control-label:after {
    content: "*";
    color: red;
    margin-left: 2px;
}

.btn-link-lc {
      text-transform:  uppercase!important;
      background-color: transparent;
}

#estimatesGrid .k-grid-content {
    height: calc(100% - 71px) !important;

    .k-state-selected input {
        color: #333;
    }
}

#estimatesGrid .k-grid-header th.k-header>.k-link {
  overflow: visible;  
}

.k-grid-header th.k-header {
   border-width: 0 0 1px 0px !important;
}

.lc-search-container {
    position: relative;
    width: 200px;
    height: 30px;
    display: inline-block;
}
/*custom buttons*/
.btn-circle {
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 1px 0;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 50px;
    /*box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)
    -webkit-box-shadow: 7px 7px 2px -3px rgba(204,204,204,0.53);
    -moz-box-shadow: 7px 7px 2px -3px rgba(204,204,204,0.53);
    box-shadow: 7px 7px 2px -3px rgba(204,204,204,0.53);*/
    box-shadow: 0 4px 8px rgba(0,0,0,.28)
}
.btn-circle:hover{
    box-shadow: 0 4px 8px rgba(0,0,0,.28);
    /*
    -webkit-box-shadow: 10px 10px 8px -4px rgba(204,204,204,0.8);
    -moz-box-shadow: 10px 10px 8px -4px rgba(204,204,204,0.8);
    box-shadow: 10px 10px 8px -4px rgba(204,204,204,0.8);*/
    transform:scale(1);
}
.btn-circle-sm {
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 1px 0;
    font-size: 15px;
    line-height: 1.33;
    border-radius: 50px;
    /*box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)
    -webkit-box-shadow: 7px 7px 2px -3px rgba(204,204,204,0.53);
    -moz-box-shadow: 7px 7px 2px -3px rgba(204,204,204,0.53);
    box-shadow: 7px 7px 2px -3px rgba(204,204,204,0.53);*/
    box-shadow: 0 4px 8px rgba(0,0,0,.28)
}
.btn-circle-sm:hover{
    box-shadow: 0 4px 8px rgba(0,0,0,.28);
    /*
    -webkit-box-shadow: 10px 10px 8px -4px rgba(204,204,204,0.8);
    -moz-box-shadow: 10px 10px 8px -4px rgba(204,204,204,0.8);
    box-shadow: 10px 10px 8px -4px rgba(204,204,204,0.8);*/
    transform:scale(1);
}
.div-center-vertical{
    display: flex;
    align-items: center;
}
.breadcrumb{
    font-size: 16px;
    color: black;
    top: -32px;
    position: relative;
    left: 0px;
    padding-left: 155px;
    margin-right: -20px;
    margin-bottom: -37px;
}
.breadcrumb a{
    font-size: 16px;
    margin-right: 10px;
    text-decoration: underline;
    text-transform: uppercase;
    color:#3c763d /*text-success*/
}



