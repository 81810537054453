@mixin clear-text-decoration() {
    color: #fff;
    text-decoration: none;
}

@mixin zero-margin-padding() {
    margin: 0;
    padding: 0;
}

.clean-menu {
    border: 1px solid #e5e5e6;
    background-color: rgb(35, 35, 35);
    color: #fff;
    
    ul {
        @include zero-margin-padding();
    };

    a, a:hover, a:focus, a:active {
        @include clear-text-decoration();
    }

    .clean-menu-item {
        display: inline-block;
        padding: 10px 10px;
        height: 40px;
    }

    .clean-menu-item:hover {
        border-bottom: 3px solid #ffffff;
    }

    .clean-menu-item:active {
        background-color: #fff;
        a {
            color: #000;
        }
    }
}
