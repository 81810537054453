@import './partial/circle.scss';
@import './partial/paginationPages.scss';
// @import './partial/style_cappuccino.scss';
// @import './partial/login.scss';
@import './partial/style.scss';
@import './partial/trailers.scss';
@import './partial/users.scss';

// THEME 
@import './theme/flex.scss';
@import './theme/clean_inputs.scss';
@import './theme/clean_menu.scss';

// PARTIALS
@import './partial/dealer/new_estimate.scss';
@import './partial/dealer/estimate_config.scss';
@import './partial/dealer/pricing.scss';
@import './partial/dealer/estimates.scss';
@import './partial/admin/items.scss';

@import './partial/modals/item_disclaimer.scss';

.no-border {
    border: 0;
}

.p-20 {
    padding: 20px;
}

.p-t-0 {
    padding-top: 0
}

.p-l-10 {
    padding-left: 10px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-t-5 {
    padding-top: 5px;
}

.m-20 {
    margin: 20px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-b-0 {
    margin-bottom: 0;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-220 {
    margin-left: 200px;
}

.m-l-100 {
    margin-left: 100px;
}

.asterix-label:after {
    content: "*";
    color: red;
    margin-left: 2px;
}